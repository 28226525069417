
import Main from "@/layout/index.vue"
import Login from "@/view/login/index.vue"
import IM404 from "@/view/404/index.vue"

export const constantRoutes = [
    {
        path: "/",
        name: 'main', //命名路由，后续做权限使用
        component: () => import('@/layout/index.vue'),
        //component:Main
        children: [
            {
                path: '/',
                name: "main",
                component: () => import('@/view/home/index.vue'),
            },
            {
                path: '/product',
                name: "product",
                component: () => import('@/view/product/index.vue'),
            },
            {
                path: "/shop",
                name: "shop",
                component: () => import('@/view/shop/index.vue'),
                //component:Shop
            },
            {
                path: "/us",
                name: "us",
                component: () => import('@/view/us/index.vue'),
            },
            {
                path: "/contact",
                name: "contact",
                component: () => import('@/view/contact/index.vue'),
            },
            {
                path: "/login",
                name: "login",
                component: () => import('@/view/login/index.vue'),
            },
            {
                path: "/mine",
                name: "mine",
                component: () => import('@/view/mine/index.vue'),
            },
        ],
    },
    
    {
        path: '/feedback',
        name: "feedback",
        component: () => import('@/view/feedback/index.vue'),
    },
    {
        path: '/complain',
        name: "complain",
        component: () => import('@/view/complain/index.vue'),
        children: [
            {
                path: '',
                name: "preface",
                component: () => import('@/view/complain/components/Preface.vue'),
            },
            {
                path: '/complain/detail',
                name: "content",
                component: () => import('@/view/complain/components/Detail.vue'),
            },
        ]
    },
    {
        path: '/cooperation',
        name: "cooperation",
        component: () => import('@/view/cooperation/index.vue'),
    },
    {
        path: '/login',
        name: "login",
        component: Login,
    },
    {
        path: "/404",
        name: "404",
        //component:()=>import('@view/404/index.vue'),
        component: IM404
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: '/404',
        name: 'others',
    }

]