import { defineComponent } from 'vue';
//import ValueScss from "@/styles/value.scss"
//import Home from './view/Home.vue'
export default defineComponent({
  name: 'App',
  components: {
    //Home
  },
  mounted() {
    document.title = '江苏流量激荡新媒体发展有限公司';
  }
});