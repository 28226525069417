import { createRouter, createWebHistory } from "vue-router";
import { constantRoutes } from "./routes";


// 解决跳转后页面位置不在top的问题, 但是无法定位到某个段落
const scrollBehavior = (to: any, from: any, savedPosition: any) => {
    // if (savedPosition && to.meta.keepAlive) {
    //     return savedPosition;
    // }
    return { left: 0, top: 0 };
}

const router = createRouter({
    history: createWebHistory(),
    routes: constantRoutes,
    scrollBehavior
});

export default router