import { defineStore } from 'pinia'
import { ref } from 'vue'
import { passwordLoginAPI, verifycodeLoginAPI } from '@/api/user/index'
import { ElMessage } from 'element-plus'

export const useUserStore = defineStore('user', () => {
    // 1. 定义管理用户数据的state
    const userInfo: any = ref({})
    // 2. 定义获取接口数据的action函数
    const getUserInfo = async (isPasswordLogin: any, data: any) => {
        let res: any = null
        if (isPasswordLogin) {
            // const { account, password } = params
            res = await passwordLoginAPI(data)
        } else {
            // const { mobile, verifycode } = params
            res = await verifycodeLoginAPI(data)
        }
        // userInfo.value = res.result
        userInfo.value = res.data
    }
    // 3. 退出登录
    const clearUserInfo = () => {
        userInfo.value = {}
    }
    // 4. 以对象的格式把state和action return
    return {
        userInfo,
        getUserInfo,
        clearUserInfo
    }

}, {
    persist: true,
})