import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3120a63a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "s-canvas"
};
const _hoisted_2 = ["width", "height"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("canvas", {
    id: "s-canvas",
    width: $props.contentWidth,
    height: $props.contentHeight
  }, null, 8, _hoisted_2)]);
}