//参考文章：https://blog.csdn.net/weixin_49602763/article/details/129145372
//参考视频：https://www.bilibili.com/video/BV1mh411F7kw?p=28&vd_source=0fb5e71d5bc070a7acad514c43423188
import axios from "axios";
import { ElMessage } from "element-plus";
import { useUserStore } from '@/store/modules/user'
import router from '@/router'

const http = axios.create({
  //baseURL: import.meta.env.VITE_URL_BASE, // api 的 base_url 注意 vue3 .env文件有强制性的 VITE_ 开头
  baseURL: "https://server.streamingboom.com/api",
  timeout: 5000,
  //headers: {'X-Custom-Header': 'foobar'}
});

// request interceptor
http.interceptors.request.use((config: any) => {
  const userStore = useUserStore()
  const token = userStore.userInfo?.token
  if (token) {
    config.headers.token = token
  }
  return config;
},
  (error) => {
    // Do something with request error
    console.log("出错啦", error); // for debug
    Promise.reject(error);
  }
);

// response interceptor
http.interceptors.response.use((res) => {
  const userStore = useUserStore()
  //console.log(response.data);
  // return response.data;
  if (res.data?.code === -1) {
    if (userStore.userInfo.token) {
      userStore.clearUserInfo()
    }
    console.log(res.data.msg);
    ElMessage({
      type: 'error',
      message: res.data.msg
    })
    router.replace({
      path: '/login',
      // router.currentRoute.value.fullPath获取当前路由
      query: { redirect: router.currentRoute.value.fullPath }
    })
    return Promise.reject('error')
  } else {
    return res.data
  }
},
  (error) => {
    //console.log("err" + error); // for debug

    // let message = '';
    // const status = error.response.status;
    // switch (status) {
    //   case 401:
    //     message = "Token过期";
    //     break;
    //   case 403:
    //     message = "无权访问";
    //     break;
    //   case 404:
    //     message = "请求地址错误";
    //     break;
    //   case 500:
    //     message = "服务器异常";
    //     break;
    //   default:
    //     message = "网络异常";
    //     break;
    // }
    ElMessage({
      type: 'error',
      message: error
    })
    return Promise.reject(error);
  }
);


// export const http = (options: any) => {
//   return new Promise((resolve, reject) => {
//     const axiosInstance = axios.create({
//       //baseURL: import.meta.env.VITE_URL_BASE, // api 的 base_url 注意 vue3 .env文件有强制性的 VITE_ 开头
//       baseURL: "https://server.streamingboom.com/api",
//       timeout: 1000,
//       //headers: {'X-Custom-Header': 'foobar'}
//     });

//     // request interceptor
//     axiosInstance.interceptors.request.use((config: any) => {
//       console.log(config);
//       //console.log(config.url);
//       //let token: string = ""; //此处换成自己获取回来的token，通常存在在cookie或者store里面
//       const token: any = localStorage.getItem('token'); //此处换成自己获取回来的token，通常存在在cookie或者store里面
//       if (token) {
//         // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
//         //config.headers["Authorization"] = 'Bearer ' + token;
//       }
//       return config;
//     },
//       (error) => {
//         // Do something with request error
//         console.log("出错啦", error); // for debug
//         Promise.reject(error);
//       }
//     );

//     // response interceptor
//     axiosInstance.interceptors.response.use((response) => {
//       console.log(response);
//       return response.data;
//     },
//       (error) => {
//         console.log("err" + error); // for debug
//         //let message = '';
//         // const abc = error.response.status;
//         // switch (abc) {
//         //   case 401:
//         //     message = "Token过期";
//         //     break;
//         //   case 403:
//         //     message = "无权访问";
//         //     break;
//         //   case 404:
//         //     message = "请求地址错误";
//         //     break;
//         //   case 500:
//         //     message = "服务器异常";
//         //     break;
//         //   default:
//         //     message = "网络异常";
//         //     break;
//         // }
//         ElMessage({
//           type:'error',
//           message: error
//         })
//         return Promise.reject(error);
//       }
//     );

//     // 请求处理
//     axiosInstance(options)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

export default http;