import request from '@/utils/http'

export const passwordLoginAPI = ({ account, password }: any) => {
  return request({
    // url: '/login',
    url: '/account/hhlogin',
    method: 'POST',
    data: {
      account,
      password,
      client: 5 //pc
    }
  })
}

export const getVerifycode = (mobile: string) => {
  return request({
    url: '/login/code',
    params: {
      mobile,
      client: 4
    }
  })
}

export const verifycodeLoginAPI = ({ mobile, code }: any) => {
  return request({
    url: '/login/code',
    method: 'POST',
    data: {
      mobile,
      code,
      client: 4
    }
  })
}

export const getUserProfileAPI = (id: any) => {
  return request({
    url: 'linkman/info',
    params: {
      linkman_sn: id
    }
  })
}