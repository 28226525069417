import { createApp, computed } from 'vue'
import App from './App.vue'
import router from './router'
import { useUserStore } from '@/store/modules/user'
import pinia from './store';

import FullPage from 'vue-fullpage.js';
//import axios from './models/api'
//import axios from 'axios'

//import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
//import en from 'element-plus/dist/locale/en.mjs'
// import '@/styles/normalize.css'
// import '@/styles/general.css'
import '@/styles/index.scss'
import './assets/styles/index.scss'

const app = createApp(App)

app.use(pinia)
app.use(FullPage)
app.use(router)
// 参考：https://blog.csdn.net/qq_39111074/article/details/129638508
const userStore = useUserStore()
const isLogin = computed(() => userStore.userInfo.token)
router.beforeEach((to, from, next) => {
    // console.log(from)
    // console.log(to);
    
    if (to.meta.requiresAuth) {
        if (isLogin.value) {
            next();
        } else {
            next({
                path: '/login',
                query: { redirect: to.fullPath } // 记住要访问的页面路由地址，后面登录后跳转会用到该值
            })
        }
    } else {
        next();
    }
})
//app.use(axios)
//app.provide('$axios',axios)
//app.use(ElementPlus)
app.mount("#app")
